import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const DataPage = () => (
  <Layout>
    <SEO title="Data Example" />
    <h1>This page is Scaffolded using graphql (from somewhere eventually)</h1>
    <p>
      COnnections aer established in gatsby-config.js and pages are hydrated on
      build in gatsby-node.js.
    </p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
);

export default DataPage;
